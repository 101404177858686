import React, {useState} from "react";
import AddFormHeader from "./components/AddFormHeader";
import {AlertMessage, Button} from "src/components";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../routes/types";
import {getEstateFlowcharts, setEstateFlowchartState} from "./EstateFlowChartSlice";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import BasicInformationContent from "./components/BasicInformationContent";
import {RevocableTrust, TrustValidation} from "../ClientManagement/models/EstateFlowchart";
import {estateFlowchartApiClient} from "./EstateFlowchartApiClient";

type AddRevocableTrustProps = {}

export const validateTrustResponse = (trust: RevocableTrust): TrustValidation => {
    const revocableTrustValidation = {
        flowchartName: trust.flowchartName ? null : "Flowchart Name is required.",
        legalName: trust.legalName ? null : "Legal name is required"
    }
    const revocableTrustHasErrors = Object.values(revocableTrustValidation).some((validation) => validation !== null)

    return (
        {revocableTrust: revocableTrustHasErrors ? revocableTrustValidation : null}
    );
}

const AddRevocableTrust: React.FC<AddRevocableTrustProps> = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const [showErrorBanner, updateShowErrorBanner] = useState(false);
    const [saveHasBeenClicked, updateSaveHasBeenClicked] = useState(false);
    const [revocableTrust, updateRevocableTrust] = useState({} as RevocableTrust);
    const estateFlowchartsInState = useAppSelector(getEstateFlowcharts);

    const RevocableTrustAlert = () => {
        return (
            <div className="row-container">
                <AlertMessage
                    className="display-flex justify-content-right alertBanner"
                    id="alertBanner"
                    fullWidth={false}
                    icon="warning"
                    showAlert={true}
                    showCloseBtn={false}
                    type="error"
                >
                    <b className="alertBannerStyleName">Required fields missing.</b>
                    &nbsp;
                    <b className="font-weight-400">All required fields must be entered to save a flowchart.</b>
                </AlertMessage>
            </div>
        );
    }

    const checkFormIsValid = () => {
        const profileValidation = validateTrustResponse(revocableTrust as RevocableTrust)
        const isValid = Object.values(profileValidation).every(x => x === null);
        updateShowErrorBanner(!isValid);
        return isValid;
    }

    const handleCreateNewTrust = async (): Promise<boolean> => {
        /**
         * API POST to create new trust;
         */
        await estateFlowchartApiClient.createRevocableTrust(id, revocableTrust);
        dispatch(setEstateFlowchartState([...estateFlowchartsInState, {revocableTrust: revocableTrust}]));
        return true;
    }

    const onSave = async () => {
        if (checkFormIsValid()) {
            let handleSave = handleCreateNewTrust;

            handleSave().then((data) => {
                if (data) {
                    /*Perform actions after the SAVE call**/
                    history.push({
                        pathname: `/Profile/${id}/ClientProfile/EstateFlow`
                    })
                }
            });
        } else {
            updateShowErrorBanner(true);
            updateSaveHasBeenClicked(true);
        }
    }

    return (
        <div className="basic-information__form-container">
            <AddFormHeader title="Add Revocable Trust"/>
            {showErrorBanner && RevocableTrustAlert()}

            <div className="form-container ">
                <BasicInformationContent saveHasBeenClicked={saveHasBeenClicked}
                                         updatedRevocableTrust={(newValue: RevocableTrust) => updateRevocableTrust(newValue)}
                                         existingRevocableTrust={revocableTrust}/>
            </div>
            <div className="form-footer textalign-right">
                <hr/>
                <Button
                    icon="none"
                    id="save_new_client_profile_button"
                    includeRef={false}
                    kind="primary"
                    onClick={onSave}
                    size="medium"
                    tabIndex={0}
                    type="button"
                >
                    SAVE
                </Button>
            </div>
        </div>
    )
}

export default AddRevocableTrust;