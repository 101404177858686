import {post} from "../core/api";
import {RevocableTrust} from "../ClientManagement/models/EstateFlowchart";


const isSuccess: (response: Response) => boolean = ({status}) => status === 200 || status === 201;

const isNoContent: (response: Response) => boolean = ({status}) => status === 204;

const extractBody = (res: Response) => res.json();


export interface ResponseStatus {
    traceId: string;
    isSuccessStatusCode: boolean;
}

function mapResponseStatus(response: Response, statusCodeValidator?: (response: Response) => boolean) {
    const responseStatus: ResponseStatus = {
        traceId: response.headers.get('trace-id')!,
        isSuccessStatusCode: statusCodeValidator ? statusCodeValidator(response) : false,
    }
    return responseStatus;
}

const createRevocableTrust = (profileId: string, requestBody: RevocableTrust)=> {
    return post(`/holistic-advice/profiles/${profileId}/estateFlowchart/revocableTrust`, requestBody)
        .then((response: Response) => mapResponseStatus(response, isSuccess))
        .catch((response: Response) => mapResponseStatus(response));
}

export const estateFlowchartApiClient = {
    createRevocableTrust
}