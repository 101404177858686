import React from 'react';
import {AssetsSummary} from "../../../Assets/models/Assets";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {assetListData, hasInEstateAssets} from "../../../Assets/AssetSummary/common/utils";
import AccountsCNWReport from "./AccountsCNWReport";
import LifeInsurancesCNWReport from "./LifeInsurancesCNWReport";
import PersonalAssetsCNWReport from "./PersonalAssetsCNWReport";
import PersonalLiabilitiesCNWReport from "./PersonalLiabilitiesCNWReport";
import EquityCompensations from "../../../Assets/CurrentNetWorth/InEstate/EquityCompensations";
import {NO_OP} from "../../../constants/common";
import AccordionWithHeader from "../../../components/Accordion/AccordionWithHeader";
import {
    hasBankAccountsForCNW,
    hasHeldAwayAccountsForCNW,
    hasInvestmentProgramsForCNW,
    hasPartiallyOwnedLegalAgreementForCNW,
    hasStandaloneAccountsForCNW
} from "./CurrentNWUtil";

export interface CurrentNetWorthAssetListReportProps {
    assetsDataForPage: AssetsSummary,
    allAssets: AssetsSummary,
    investorGroup: InvestorGroupType,
    showDeathBenefit?: boolean,
    isDetailed: boolean,
    profileId: string
}

const CurrentNetWorthAssetListReport = ({
                                            isDetailed,
                                            assetsDataForPage,
                                            investorGroup,
                                            showDeathBenefit = false,
                                            allAssets,
                                            profileId
                                        }: CurrentNetWorthAssetListReportProps) => {

    const hasAccountsInPageData = hasStandaloneAccountsForCNW(assetsDataForPage) || hasInvestmentProgramsForCNW(assetsDataForPage) || hasHeldAwayAccountsForCNW(assetsDataForPage) || hasBankAccountsForCNW(assetsDataForPage) || hasPartiallyOwnedLegalAgreementForCNW(assetsDataForPage);

    const hasPersonalAssetsInPageData = assetsDataForPage.personalAssets.data.length > 0;
    const hasLifeInsurancesInPageData = assetsDataForPage.lifeInsurances.data.length > 0;
    const hasPersonalLiabilitiesInPageData = assetListData(assetsDataForPage).hasInEstatePersonalLiability;

    const allItems = [
        hasAccountsInPageData ? "Accounts" : '',
        hasPersonalAssetsInPageData ? "PersonalAssets" : '',
        hasLifeInsurancesInPageData ? "LifeInsurances" : '',
        hasPersonalLiabilitiesInPageData ? "PersonalLiabilities" : ''
    ].filter(item => item.length > 0);

    const columnTitles = investorGroup.partnerMember
        ? ['Asset Type', investorGroup.primaryMember.firstName, investorGroup.partnerMember.firstName, 'Joint', 'Present Value']
        : ['Asset Type', 'Present Value'];

    return (
        <section>
            <div className="asset-list">
                <div className="accordion-tables">
                    <AccordionWithHeader
                        ignoreExpandedItems={true}
                        accordionId={isDetailed ? "CurrentNetWorthInEstateAssetListReport" : "CurrentNetWorthInEstateAssetListCollapsedReport"}
                        allItems={allItems}
                        columnTitles={columnTitles}
                        renderHeader={hasInEstateAssets(allAssets)}
                        headerProps={{
                            testId: "current-net-worth-headers",
                            className: "current-net-worth-grid",
                            gridSpanClass: investorGroup.partnerMember ? '' : 'grid-span-4'
                        }}
                    >
                        <div className="assets">
                            <AccountsCNWReport assetsDataForPage={assetsDataForPage}
                                               investorGroup={investorGroup} allAssetsCNW={allAssets}
                                               caption={JSON.stringify(allAssets.accounts.data[0]) === JSON.stringify(assetsDataForPage.accounts.data[0]) ? "" : "continued"}/>
                            <EquityCompensations profileId={profileId} assetsData={assetsDataForPage}
                                                 investorGroup={investorGroup}
                                                 onRemoveAssetClick={NO_OP}
                                                 captionLabel={JSON.stringify(allAssets.equityCompensations.data[0]) === JSON.stringify(assetsDataForPage.equityCompensations.data[0]) ? "" : "continued"}/>
                            <PersonalAssetsCNWReport assetsDataForPage={assetsDataForPage}
                                                     investorGroup={investorGroup} allAssetsCNW={allAssets}
                                                     caption={JSON.stringify(allAssets.personalAssets.data[0]) === JSON.stringify(assetsDataForPage.personalAssets.data[0]) ? "" : "continued"}/>
                            <LifeInsurancesCNWReport assetsDataForPage={assetsDataForPage}
                                                     investorGroup={investorGroup} allAssetsCNW={allAssets}
                                                     showDeathBenefit={showDeathBenefit}
                                                     caption={JSON.stringify(allAssets.lifeInsurances.data[0]) === JSON.stringify(assetsDataForPage.lifeInsurances.data[0]) ? "" : "continued"}/>
                            <PersonalLiabilitiesCNWReport assetsDataForPage={assetsDataForPage}
                                                          allAssetsCNW={allAssets}
                                                          caption={JSON.stringify(allAssets.personalLiabilities[0]) === JSON.stringify(assetsDataForPage.personalLiabilities[0]) ? "" : "continued"}
                                                          investorGroup={investorGroup}/>
                        </div>

                    </AccordionWithHeader>
                </div>
            </div>
        </section>
    );
}

export default CurrentNetWorthAssetListReport;
