import {DatePicker, Input, RedAsterisk, RequiredFieldsSubheader, UnderlinedHeader} from "../../components";
import React, {ChangeEvent, useEffect, useState} from "react";
import {DISPLAY_DATE_FORMAT} from "../../constants/common";
import moment, {Moment} from "moment";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {RevocableTrust} from "../../ClientManagement/models/EstateFlowchart";

type BasicInformationContentProps = {
    saveHasBeenClicked : boolean,
    updatedRevocableTrust: Function,
    existingRevocableTrust: RevocableTrust
}

const BasicInformationContent : React.FC<BasicInformationContentProps> =  ({saveHasBeenClicked,
                                                                               updatedRevocableTrust,
                                                                               existingRevocableTrust}) => {

    const [basicInformation, updateBasicInformation] = useState(existingRevocableTrust as RevocableTrust);

    useEffect(() => {
        updatedRevocableTrust(basicInformation)
    }, [basicInformation]);

    return (
        <section aria-label="Basic Info" className="basic-information__form">
            <UnderlinedHeader leftAlignedContent={<><h4>Basic Information</h4> <RequiredFieldsSubheader /></>} />

            <div className="textalign-right form-main">
                <div className="layout-data-entry-form__field" >
                    <label className={"h5"} data-testid={'flowchart-name'}>Flowchart Name<RedAsterisk/></label>
                    <Input
                        name="flowchartNameField"
                        aria-label="flowchartName"
                        aria-labelledby="flowchartNameFieldInput-label"
                        id="flowchartNameFieldFieldInput"
                        size="medium"
                        type="text"
                        value={basicInformation.flowchartName}
                        disabled={false}
                        error={saveHasBeenClicked && !basicInformation.flowchartName ? "Flowchart Name is required." : undefined}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                            ...basicInformation, flowchartName: e.target.value})}
                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <label className={"h5"} data-testid={'legal-name'}>Legal Name of Trust or Entity<RedAsterisk/></label>
                    <Input
                        name="legalNameField"
                        aria-label="legalName"
                        aria-labelledby="legalNameFieldInput-label"
                        id="legalNameFieldFieldInput"
                        size="medium"
                        type="text"
                        value={basicInformation.legalName}
                        disabled={false}
                        error={saveHasBeenClicked && !basicInformation.legalName ? "Legal Name of Trust or Entity is required." : undefined}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                            ...basicInformation, legalName: e.target.value})}

                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <label className={"h5"} data-testid={'nick-name'}>Nickname</label>
                    <Input
                        name="nickNameField"
                        aria-label="nickName"
                        aria-labelledby="nickNameFieldInput-label"
                        id="nickNameFieldFieldInput"
                        size="medium"
                        type="text"
                        value={basicInformation.nickName ? basicInformation.nickName : ''}
                        disabled={false}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => updateBasicInformation({
                            ...basicInformation, nickName: e.target.value})}

                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <label className={"h5"} data-testid={'date-created'}>Date Created</label>
                    <DatePicker
                        className="createdDateField"
                        id="createdDateInput"
                        aria-label="createdDateInput"
                        aria-labelledby="createdDateInput-label"
                        displayFormat={DISPLAY_DATE_FORMAT}
                        hideKeyboardShortcutsPanel
                        // isOutsideRange ensures that future birthdays are invalid
                        isOutsideRange={(date: Moment) => date.isAfter(moment())}
                        date={basicInformation.trustCreationDate ? moment(basicInformation.trustCreationDate) : undefined}
                        onDateChange={
                            (date: Moment) => {
                                const trustCreatedDate = date.toDate();
                                updateBasicInformation({
                                    ...basicInformation, trustCreationDate: trustCreatedDate})
                            }
                        }
                        disabled={false}
                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <div className={"textarea-label"}><label className={"h5"} data-testid={'record-of-amendments'}>Record of Amendments</label>
                        <span>{basicInformation?.recordOfAmendments ? basicInformation?.recordOfAmendments?.length : 0}/200</span></div>
                    <textarea
                        className="record-of-amendments"
                        data-testid='record-of-amendments-field'
                        autoFocus={true}
                        rows={5}
                        cols={50}
                        maxLength={200}
                        defaultValue={basicInformation.recordOfAmendments ? `${basicInformation.recordOfAmendments}` : ''}
                        onChange={(e) => {
                            updateBasicInformation({
                                ...basicInformation, recordOfAmendments: e.target.value})}
                        }
                    />
                </div>
            </div>
        </section>
    );
}

export default BasicInformationContent;