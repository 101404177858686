import React, {ChangeEvent, useEffect, useMemo, useRef, useState} from "react";
import {HandleSaveResponse, StrategyHeader} from "./components/StrategyHeader";
import {StrategySidebar} from "./components/StrategySidebar";
import {DataEntrySummaryItem} from "../components/DataEntry/DataEntrySummary";
import {
    CurrencyInput,
    EmptyStateContainer,
    Input,
    RedAsterisk,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../components";
import {
    formatCurrency,
    formatNumberRoundedToTwoDecimals,
    formatPercentWithWholeNumberAndTwoDecimals
} from "../utils/format";
import PercentInput from "../components/Input/PercentInput";
import {wealthPOApiClient} from "./WealthPOApiClient";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../routes/types";
import {
    EstimatedImpact,
    FailedAPICall,
    GRATEstimatedImpactRequestDTO,
    GRATRates,
    initialTwoYearGRATStrategy,
    PayoutPercentagesRequestDTO,
    StrategyType,
} from "./WealthPOTypes";
import useProfileEditableState from "../hooks/useProfileEditableState";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../components/Modal/Error/GenericErrorModal";
import moment from "moment";

export interface AddEditGRATProps {
    header: string,
    defaultDescription: string,
}

const AddEditGRAT: React.FC<AddEditGRATProps> = ({
                                                     header,
                                                     defaultDescription
                                                 }) => {
    const {id} = useParams<RouteWithId>();

    const [description, setDescription] = useState<string>(defaultDescription);
    const [contributionAmountDisplayValue, setContributionAmountDisplayValue] = useState<number>(0);
    const [contributionAmount, setContributionAmount] = useState<number>(0);
    const [increaseInPaymentDisplayValue, setIncreaseInPaymentDisplayValue] = useState<string>("20");
    const [increaseInPayment, setIncreaseInPayment] = useState<number>(20);
    const [annuityRateDisplayValue, setAnnuityRateDisplayValue] = useState<string>("0.00");
    const [initialAnnuityRateDisplayValue, setInitialAnnuityRateDisplayValue] = useState<string>("0.00")
    const [annuityRate, setAnnuityRate] = useState<number | undefined>(undefined);
    const [riskAssetGrowthRate, setRiskAssetGrowthRate] = useState<string>("");
    const [payoutPercentages, setPayoutPercentages] = useState<number[]>([])
    const [error, setError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [showTempErrorMessage, setShowTempErrorMessage] = useState(false);
    const [estimatedImpact, setEstimatedImpact] = useState<EstimatedImpact>(initialTwoYearGRATStrategy.estimatedImpact)
    const [initialFormUpdated, setInitialFormUpdated] = useState<boolean>(false);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const estimatedImpactAbortControllerRef = useRef<AbortController | undefined>(undefined);
    const [failedApiCall, setFailedApiCall] = useState<FailedAPICall>();

    const fetchReferenceData = () => {
        wealthPOApiClient.getGRATRatesReferenceData(id)
            .then((gratRates: GRATRates) => {
                const riskAssetRateDto = gratRates.growthRates.rateDtos.find(rateDto => rateDto.id === "RiskAsset");
                if (riskAssetRateDto) {
                    const growthRate = riskAssetRateDto.value * 100;
                    const s = growthRate.toPrecision(3);
                    setRiskAssetGrowthRate(s)
                }

                // Hardcoding to 4.4 according to current (11/24) AC. In future will use rate from reference data: gratRates.hurdleRate
                const hurdleRate = formatPercentWithWholeNumberAndTwoDecimals(0.044, false)
                setAnnuityRate(Number(hurdleRate))
                setAnnuityRateDisplayValue(hurdleRate)
                setInitialAnnuityRateDisplayValue(hurdleRate)
            })
            .catch(err => {
                setFailedApiCall(FailedAPICall.REFERENCE_DATA);
                openErrorModal(err)
            })
    }

    const fetchPayoutPercentages = () => {
        if (annuityRate) {
            const payoutPercentagesRequestDTO = {
                contributionAmount,
                increaseInPayment,
                annuityRate
            } as PayoutPercentagesRequestDTO

            wealthPOApiClient.getPayoutPercentages(id, payoutPercentagesRequestDTO)
                .then(payoutPercentagesResponseDTO => {
                    setPayoutPercentages(payoutPercentagesResponseDTO.payoutPercentages)
                })
                .catch(err => {
                    setFailedApiCall(FailedAPICall.PAYOUT_PERCENTAGES);
                    openErrorModal(err)
                })
        }
    }

    const getEstimatedImpact = (requestBody: GRATEstimatedImpactRequestDTO, signal?: AbortSignal): Promise<EstimatedImpact> => {
        return wealthPOApiClient.getEstimatedImpactForGRAT(id, requestBody, signal);
    }

    const fetchEstimatedImpact = async () => {
        estimatedImpactAbortControllerRef.current = new AbortController();
        const {signal} = estimatedImpactAbortControllerRef.current;

        getEstimatedImpact({
            strategyId: undefined,
            strategyType: StrategyType.TWO_YEAR_GRAT,
            contributionAmount: contributionAmount,
            yearsUntilFlow: 0,
            yearsOfFlow: 1,
            yearsOfGrantorStatus: 0,
            payoutPercentages: payoutPercentages
        }, signal)
            .then(estimatedImpactResponse => {
                setEstimatedImpact(estimatedImpactResponse);
            })
            .catch((err) => {
                setFailedApiCall(FailedAPICall.ESTIMATED_IMPACT);
                openErrorModal(err);
            });
    }

    useEffect(() => {
        fetchReferenceData();
    }, [id])

    useEffect(() => {
        if (!initialFormUpdated) {
            setInitialFormUpdated(true)
        }
        fetchPayoutPercentages();
    }, [contributionAmount, increaseInPayment, annuityRate])

    useEffect(() => {
        estimatedImpactAbortControllerRef.current = undefined;

        if (initialFormUpdated) {
            fetchEstimatedImpact().then();
        }

        return () => {
            if (estimatedImpactAbortControllerRef.current) {
                estimatedImpactAbortControllerRef.current.abort();
            }
        }
    }, [payoutPercentages])

    const openErrorModal = (e: Error | any) => {
        setError({
            isOpen: true,
            header: "Unable to Load This Page",
            message: (
                <>
                    <p>Check your VPN connection and retry.</p>
                    <p>If the problem persists, contact <a href="mailto:GPIITSupport@ntrs.com">GPS Technical
                        Support</a> at GPIITSupport@ntrs.com and provide the following details:</p>
                </>
            ),
            profileId: id,
            time: new Date(),
            errorDetail: `Failed to load ${header} page (${e.status})`,
            operationId: e.headers.get('trace-id')
        })
    }

    const closeErrorModal = () => {
        setError({...error, isOpen: false});
    };

    const handleRetryClickInCommunicationErrorModal = () => {
        closeErrorModal();
        switch (failedApiCall) {
            case(FailedAPICall.REFERENCE_DATA):
                fetchReferenceData();
                break;
            case(FailedAPICall.PAYOUT_PERCENTAGES):
                fetchPayoutPercentages();
                break;
            case(FailedAPICall.ESTIMATED_IMPACT):
                fetchEstimatedImpact().then();
                break;
        }
    }

    const handleCloseClickInCommunicationErrorModal = () => {
        closeErrorModal();
        setShowTempErrorMessage(true);
    }

    const memoizedStrategySummary = useMemo(() => {
        return [
            {
                label: "Contribution Amount",
                value: formatCurrency(contributionAmount),
                testId: "contributionAmount"
            } as DataEntrySummaryItem,
            {
                label: "% Increase in Payment",
                value: `${formatNumberRoundedToTwoDecimals(increaseInPayment)}%`,
                testId: "increaseInPayment"
            } as DataEntrySummaryItem,
            {
                label: "Annuity Rate",
                value: `${formatNumberRoundedToTwoDecimals(annuityRate ? annuityRate : 0)}%`,
                testId: "annuityRate"
            } as DataEntrySummaryItem
        ]
    }, [contributionAmount, increaseInPayment, annuityRate])

    const memoizedEstimatedImpact = useMemo(() => {
        return [
            {
                label: "Amount to Beneficiaries",
                value: (estimatedImpact.amountToBeneficiaries !== undefined) ? formatCurrency(estimatedImpact.amountToBeneficiaries) : undefined,
                testId: "amountToBeneficiaries",
            } as DataEntrySummaryItem,
            {
                label: "Est. Estate Tax",
                value: (estimatedImpact.impactToEstEstateTax !== undefined) ? formatCurrency(estimatedImpact.impactToEstEstateTax) : undefined,
                testId: "estEstateTax",
            } as DataEntrySummaryItem,
        ]
    }, [estimatedImpact])

    if (error.isOpen) {
        return (
            <GenericErrorModal
                errorModalData={error}
                onClickButton={handleRetryClickInCommunicationErrorModal}
                onRequestClose={handleCloseClickInCommunicationErrorModal}
                buttonText="Retry"
                buttonProps={
                    {
                        primary: true,
                        className: 'full-width center-align',
                        iconPosition: 'left',
                        iconName: 'refresh'
                    }
                }
                showAlertIcon={false}
            />
        )
    }

    if (showTempErrorMessage) {
        return (
            <EmptyStateContainer
                className="no-wpo-summary-placeholder"
                title="Unable to Load This Page"
                size="large"
                description="Try again later."
            />
        )
    }

    return (
        <div>
            <StrategyHeader
                header={header}
                handleSave={() => {
                    return {
                        isSuccess: true,
                        errors: {}
                    } as HandleSaveResponse
                }}
                handleCancel={() => false}
            />

            <div className="wealthpo-strategy__form">
                <article>
                    <section aria-label="Strategy Details">
                        <UnderlinedHeader
                            className={"wealthpo-strategy__underlined-header"}
                            primaryText="Strategy Details"
                            rightAlignedContent={<RequiredFieldsSubheader/>}
                        />
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"} data-testid={'description'}>Description<RedAsterisk/></label>
                            <Input
                                name="descriptionField"
                                aria-label="description"
                                aria-labelledby="descriptionFieldInput-label"
                                id="descriptionFieldInput"
                                removeMarginTop
                                size="medium"
                                type="text"
                                value={description}
                                readOnly={isProfileWithProposalsOrArchived}
                                // error={errorOnSave ? "Description is required." : undefined}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}

                            />
                        </div>
                        <div className="layout-data-entry-form__field" style={{alignItems: "start"}}>
                            <label className={"h5"} data-testid={'contributionAmountInput'}>Contribution Amount</label>
                            <div className="annual-flow-input-field">
                                <CurrencyInput
                                    name="contributionAmountField"
                                    aria-label="contributionAmount"
                                    id="contributionAmountFieldInput"
                                    size="medium"
                                    value={contributionAmountDisplayValue}
                                    readOnly={isProfileWithProposalsOrArchived}
                                    textAlign='left'
                                    onChangeValue={e => isNaN(parseInt(e.target.value)) ? setContributionAmountDisplayValue(0) : setContributionAmountDisplayValue(parseInt(e.target.value))}
                                    onBlur={() => {
                                        setContributionAmount(contributionAmountDisplayValue);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="layout-data-entry-form__field" style={{alignItems: "start"}}>
                            <label className={"h5"} data-testid={'increaseInPaymentInput'}>% Increase in Payment</label>
                            <div className="percent-input-field">
                                <PercentInput
                                    hideLabel
                                    aria-label="increaseInPayment"
                                    label="increaseInPayment"
                                    size="medium"
                                    defaultValue={"20"}
                                    value={increaseInPaymentDisplayValue}
                                    disabled={isProfileWithProposalsOrArchived}
                                    textAlign='left'
                                    onChange={e => setIncreaseInPaymentDisplayValue(e)}
                                    onBlur={() => {
                                        setIncreaseInPayment(Number(increaseInPaymentDisplayValue));
                                    }}
                                />
                            </div>
                        </div>
                        <div className="layout-data-entry-form__field" style={{alignItems: "start"}}>
                            <label className={"h5"} data-testid={'annuityRateInput'}>Annuity Rate</label>
                            <div className="percent-input-field">
                                <PercentInput
                                    hideLabel
                                    aria-label="annuityRate"
                                    label="annuityRate"
                                    size="medium"
                                    defaultValue={annuityRateDisplayValue}
                                    value={annuityRateDisplayValue}
                                    disabled={isProfileWithProposalsOrArchived}
                                    textAlign='left'
                                    onChange={e => setAnnuityRateDisplayValue(e)}
                                    onBlur={() => {
                                        setAnnuityRate(Number(annuityRateDisplayValue));
                                    }}
                                />
                                <span
                                    className="subheader">7520 Hurdle Rate for month of {moment.months(moment().month())} {moment().year()}: {initialAnnuityRateDisplayValue}%</span>
                            </div>
                        </div>
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"}>Growth Rate</label>
                            <div className="growth-rate-value">
                                <span aria-label={'growthRate'}>Triple Net Risk Asset ({riskAssetGrowthRate}%)</span>
                            </div>
                        </div>
                        <div className="layout-data-entry-form__field">
                            <label className={"h5 align-self-start"}>Payout Percentages</label>
                            <div className={"payout-percentages"}>
                                {payoutPercentages.map((value, index) => {
                                    const percentage = formatPercentWithWholeNumberAndTwoDecimals(value)
                                    return (
                                        <span
                                            key={index + 1}
                                            role="payout-percentage"
                                            aria-label="payout-percentage">{`Year ${index + 1} Annuity Rate: ${percentage}`}
                                    </span>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                </article>

                <StrategySidebar
                    strategySummary={memoizedStrategySummary}
                    estimatedImpact={memoizedEstimatedImpact}
                />
            </div>

        </div>
    )
}

export default AddEditGRAT;