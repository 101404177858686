import React, {useEffect, useState} from 'react';
import {AssetsSummary} from "./models/Assets";
import {AlertBanner} from "../components";
// InfoBannerForStandaloneAndBankingDeposit
export interface InfoBannerForStandaloneAndBankingDepositProps {
    assets: AssetsSummary;
}

const InfoBannerForStandaloneAndBankingDeposit = ({assets}: InfoBannerForStandaloneAndBankingDepositProps) => {

    const [showInfoBanner, setShowInfoBanner] = useState<boolean>(false);
    useEffect(() => {
        if (assets.accounts.data.length > 0 && (assets.bankingAccounts && assets.bankingAccounts.length > 0)) {
            setShowInfoBanner(true);
        } else if(assets.bankingAccounts.length > 0 && (assets.accounts.data.length === 0)) {
            setShowInfoBanner(false);
        }else {
            setShowInfoBanner(false);
        }
    }, [assets]);

    return <AlertBanner
        className={'marginbottom-lg'}
        fullWidth={false}
        icon="info"
        type="info"
        showAlert={showInfoBanner}
        showCloseBtn={true}
        message={"Northern Trust accounts have been added. Please review standalone accounts to ensure duplicates are removed."}
        onClose={() => setShowInfoBanner(false)}
    />
}

export default InfoBannerForStandaloneAndBankingDeposit;