import {AssetsSummary} from "../../../Assets/models/Assets";
import {PAGE_CONSTANTS} from "../AssetSummaryDetailReport/AssetsReportUtils";
import {assetListData, hasAssets, hasLiabilities} from "../../../Assets/AssetSummary/common/utils";
import {defaultAssetsState} from "../../../Assets/clientAssetsSlice";
import {HeldAwayAccountSummary, InvestmentProgram, LegalAgreement} from "../../../Assets/models/InvestmentProgram";
import {PersonalLiabilitySummary} from "../../../Assets/models/PersonalLiability";
import {EquityCompensationFormData} from "../../../Assets/models/EquityCompensation";
import {StandaloneAccount} from "../../../Assets/models/StandaloneAccount";
import {PersonalAsset} from "../../../Assets/models/PersonalAsset";
import {GeneralInflow} from "../../../Assets/models/GeneralInflow";
import {SocialSecurity} from "../../../Assets/models/SocialSecurity";
import {LifeInsurance} from "../../../Assets/models/LifeInsurance";
import {PartiallyOwnedLegalAgreement} from "../../../Assets/models/PartiallyOwnedInvestmentAccount";
import {BankingAccountsSummary} from "../../../Assets/models/BankingAccounts";

type CNWAssetTypeList =
    StandaloneAccount[]
    | PersonalAsset[]
    | GeneralInflow[]
    | SocialSecurity[]
    | LegalAgreement[]
    | HeldAwayAccountSummary[]
    | PartiallyOwnedLegalAgreement[]
    | PersonalLiabilitySummary[]
    | LifeInsurance[]
    | EquityCompensationFormData[]
    | BankingAccountsSummary[]

export const hasStandaloneAccountsForCNW = (clientAssetsData: AssetsSummary) => {
    return clientAssetsData && clientAssetsData.accounts?.data?.length > 0;
}

export const hasInvestmentProgramsForCNW = (clientAssetsData: AssetsSummary) => {
    return clientAssetsData && clientAssetsData.investmentProgram && clientAssetsData.investmentProgram.legalAgreements.length > 0
}


export const hasHeldAwayAccountsForCNW = (clientAssetsData: AssetsSummary) => {
    return clientAssetsData && clientAssetsData.investmentProgram && clientAssetsData.investmentProgram.heldAwayAccounts && clientAssetsData.investmentProgram.heldAwayAccounts.length > 0
}

export const hasBankAccountsForCNW = (clientAssetsData: AssetsSummary) => {
    return clientAssetsData && clientAssetsData.bankingAccounts && clientAssetsData.bankingAccounts.length > 0
}

export const hasPartiallyOwnedLegalAgreementForCNW = (clientAssetsData: AssetsSummary) => {
    return clientAssetsData && clientAssetsData.partiallyOwnedLegalAgreements && clientAssetsData.partiallyOwnedLegalAgreements.length > 0;
}

const hasGeneralInflowsForCNW = (clientAssetsData: AssetsSummary) => {
    return clientAssetsData && clientAssetsData.generalInflows?.data?.length > 0;
}

const hasSocialSecurity = (clientAssetsData: AssetsSummary) => {
    return clientAssetsData && clientAssetsData.socialSecurities?.data?.length > 0;
}

const populateCNWAssetsList = (assetType: keyof AssetsSummary, data: CNWAssetTypeList, clientAssets: AssetsSummary,isLegalAgreements:boolean): Partial<AssetsSummary> => {

    switch (assetType) {
        case 'investmentProgram':
            if(isLegalAgreements)
            {
                return {
                    investmentProgram: {
                        ...clientAssets.investmentProgram,
                        legalAgreements: data as LegalAgreement[]
                    } as InvestmentProgram
                }
            }else {
                return {
                    investmentProgram: {
                        ...clientAssets.investmentProgram,
                        heldAwayAccounts: data as HeldAwayAccountSummary[]
                    } as InvestmentProgram
                }
            }
        case 'partiallyOwnedLegalAgreements':
            return {
                partiallyOwnedLegalAgreements: data as PartiallyOwnedLegalAgreement[]
            }

        case 'bankingAccounts':
            return {
                bankingAccounts: data as BankingAccountsSummary[]
            }
        case 'personalLiabilities':
            return {
                personalLiabilities: data as PersonalLiabilitySummary[]
            }
        case 'equityCompensations':
            return {
                equityCompensations: {
                    ...clientAssets.equityCompensations,
                    data:data as EquityCompensationFormData[]
                }
            }
        default:
            const assetTypeData = clientAssets[assetType];
            if (typeof assetTypeData != 'number') {
                return {
                    [assetType]: {
                        ...assetTypeData,
                        data
                    }
                }
            } else {
                return {}
            }
    }
};

const getEstimatesForCNWAssets = (assetsData: AssetsSummary, pageScore: number, pageAssets: AssetsSummary, calculatedAssets: AssetsSummary[]) => {
    if (hasAssets(assetsData)) {
        const __ret = getEstimatesForCNWInvestmentsAndAccounts(assetsData, pageScore, pageAssets, calculatedAssets);
        pageScore = __ret.pageScore;
        pageAssets = __ret.pageAssets;

        if (assetsData.equityCompensations.data.length > 0) {
            pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
            const result = setPageCNWAssetData('equityCompensations',
                pageAssets, pageScore, assetsData.equityCompensations.data, calculatedAssets, assetsData,false);
            pageScore = result.pageScore;
            pageAssets = result.pageAssets;
        }

        if (hasGeneralInflowsForCNW(assetsData) || hasSocialSecurity(assetsData)) {
            pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;

            if (hasSocialSecurity(assetsData)) {
                const result = setPageCNWAssetData('socialSecurities',
                    pageAssets, pageScore, assetsData.socialSecurities.data, calculatedAssets, assetsData,false);
                pageScore = result.pageScore;
                pageAssets = result.pageAssets;
            }
        }

        if (assetsData.personalAssets.data.length > 0) {
            pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
            const result = setPageCNWAssetData('personalAssets',
                pageAssets, pageScore, assetsData.personalAssets.data, calculatedAssets, assetsData,false);
            pageScore = result.pageScore;
            pageAssets = result.pageAssets;
        }

        if (assetsData.lifeInsurances.data.length > 0) {
            pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
            const result = setPageCNWAssetData('lifeInsurances',
                pageAssets, pageScore, assetsData.lifeInsurances.data, calculatedAssets, assetsData,false);
            pageScore = result.pageScore;
            pageAssets = result.pageAssets;
        }
    }
    return {pageScore, pageAssets};
}

const getEstimatesForCNWInvestmentsAndAccounts = (assetsData: AssetsSummary, pageScore: number, pageAssets: AssetsSummary, calculatedAssets: AssetsSummary[]) => {

    if (hasStandaloneAccountsForCNW(assetsData) || hasInvestmentProgramsForCNW(assetsData)
        || hasHeldAwayAccountsForCNW(assetsData) || hasBankAccountsForCNW(assetsData)|| hasPartiallyOwnedLegalAgreementForCNW(assetsData)) {
        pageScore = pageScore + PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
        if (hasStandaloneAccountsForCNW(assetsData)) {
            const result = setPageCNWAssetData('accounts', pageAssets, pageScore,
                assetsData.accounts.data, calculatedAssets, assetsData, false);
            pageScore = result.pageScore;
            pageAssets = result.pageAssets;
        }

        if (hasInvestmentProgramsForCNW(assetsData)) {
            const result = setPageCNWAssetData('investmentProgram', pageAssets, pageScore,
                (assetsData.investmentProgram?.legalAgreements || []), calculatedAssets, assetsData,true);
            pageScore = result.pageScore;
            pageAssets = result.pageAssets;
        }

        if (hasHeldAwayAccountsForCNW(assetsData)) {
            const result = setPageCNWAssetData('investmentProgram', pageAssets, pageScore,
                (assetsData.investmentProgram?.heldAwayAccounts || []), calculatedAssets, assetsData, false);
            pageScore = result.pageScore;
            pageAssets = result.pageAssets;
        }

        if(hasPartiallyOwnedLegalAgreementForCNW(assetsData)){
            const result = setPageCNWAssetData('partiallyOwnedLegalAgreements',
                pageAssets, pageScore,
                (assetsData.partiallyOwnedLegalAgreements || []),
                calculatedAssets, assetsData, false);
            pageScore = result.pageScore;
            pageAssets = result.pageAssets;
        }

        if (hasBankAccountsForCNW(assetsData)) {
            const result = setPageCNWAssetData('bankingAccounts', pageAssets, pageScore,
                (assetsData.bankingAccounts || []), calculatedAssets, assetsData, false);
            pageScore = result.pageScore;
            pageAssets = result.pageAssets;
        }

    }
    return {pageScore, pageAssets};
}

const splitCNWAssets = (assetsData: AssetsSummary) => {
    const calculatedAssets: AssetsSummary[] = [];
    let pageScore = PAGE_CONSTANTS.PAGE_PADDING_HEIGHT + PAGE_CONSTANTS.SECTION_HEADER_HEIGHT;
    let pageAssets: AssetsSummary = {...defaultAssetsState};

    const __ret = getEstimatesForCNWAssets(assetsData, pageScore, pageAssets, calculatedAssets);
    pageScore = __ret.pageScore;
    pageAssets = __ret.pageAssets;

    if (assetListData(assetsData).hasInEstatePersonalLiability) {
        if (hasAssets(assetsData)) {
            pageScore += PAGE_CONSTANTS.ASSET_LIABILITY_BOTTOM_MARGIN_FOR_CURRENT_NW;
        }
        pageScore += PAGE_CONSTANTS.ASSET_LIABILITY_BOTTOM_MARGIN_FOR_CURRENT_NW;
        pageScore += PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;

        const result = setPageCNWAssetData('personalLiabilities', pageAssets, pageScore,
            assetListData(assetsData).inEstatePersonalLiabilities, calculatedAssets, assetsData,false);
        pageAssets = result.pageAssets;
    }

    if (pageAssets !== defaultAssetsState) {
        calculatedAssets.push(pageAssets);
    }

    let spaceLeft: number ;
    if (PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT < pageScore) spaceLeft = 2 * PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT - pageScore;
    else spaceLeft = PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT - pageScore;
    return {assetSummariesData: calculatedAssets, remainingSpaceAfterSplittingAssets: spaceLeft};
}

const setPageCNWAssetData = (assetType: keyof AssetsSummary,
                             pageAssets: AssetsSummary,
                             pageScore: number,
                             data: CNWAssetTypeList,
                             calculatedAssetsPages: AssetsSummary[],
                             assetsData: AssetsSummary,
                             isLegalAgreements:boolean) => {

    const nestedAssetsHeaderLength = (assetType === 'investmentProgram' || 'bankingAccounts' ||'accounts' || 'socialSecurities' || 'partiallyOwnedLegalAgreements' ) ? 1 : 0;
    let updatedPageScore = pageScore + (data.length + nestedAssetsHeaderLength) * PAGE_CONSTANTS.TABLE_ROW_HEIGHT_FOR_CURRENT_NW;

    if (updatedPageScore < PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT) {
        pageAssets = {
            ...pageAssets,
            ...populateCNWAssetsList(assetType, data, assetsData,isLegalAgreements)
        }
    } else {
        const remainingPageSpace = PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT - pageScore;
        let noOfAssetsPageCanFit = 0;
        if (remainingPageSpace > 0) {
            // If there is enough space to fit 3 assets, slice the 3 and push them to current page
            noOfAssetsPageCanFit = Math.floor(remainingPageSpace / PAGE_CONSTANTS.TABLE_ROW_HEIGHT_FOR_CURRENT_NW);
            if (noOfAssetsPageCanFit > 0) {
                const assetsThatCanFitInCurrentPage = data.slice(0, noOfAssetsPageCanFit);
                pageAssets = {
                    ...pageAssets,
                    ...populateCNWAssetsList(assetType, assetsThatCanFitInCurrentPage, assetsData,isLegalAgreements)
                };
            }
        }
        // Push Current Page
        calculatedAssetsPages.push(pageAssets);

        // Creating New Page
        updatedPageScore = PAGE_CONSTANTS.PAGE_PADDING_HEIGHT + PAGE_CONSTANTS.SECTION_HEADER_HEIGHT + PAGE_CONSTANTS.ASSET_LIABILITY_BOTTOM_MARGIN_FOR_CURRENT_NW;
        pageAssets = defaultAssetsState;

        // For rest of the assets, split the remaining data recursively until all assets are fit into 1 or more pages
        const remainingAssets = data.slice(noOfAssetsPageCanFit);

        if (remainingAssets.length > 0) {

            const result = setPageCNWAssetData(assetType, pageAssets, updatedPageScore, remainingAssets, calculatedAssetsPages, assetsData,isLegalAgreements);
            updatedPageScore = result.pageScore;
            pageAssets = result.pageAssets;
        }
    }

    return {pageScore: updatedPageScore, pageAssets};
}

export {
    splitCNWAssets
}