import React, {useState} from "react";
import {formatCurrency} from "../../../utils/format";
import {Button} from "../../../components";
import {NO_OP} from "../../../constants/common";
import {BankingAccountReadModel, BankingAccountWriteModel} from "../../models/BankingAccount";
import {useAppSelector} from "../../../store/hooks";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import AddBankingAccountModal from "./AddBankingAccountModal";

export type BankAccountSearchResultsProps = {
    bankingAccounts: BankingAccountReadModel[],
    onSave: (selectedAccounts: BankingAccountWriteModel[]) => void
};

export function BankAccountSearchResults({bankingAccounts, onSave} : BankAccountSearchResultsProps) {
    const noValue = <span className="no-value">&ndash;&ndash;</span>;
    const accountLength = bankingAccounts.length;
    const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
    const [isHeaderChecked, setIsHeaderChecked] = useState<boolean>(false);
    const [checkedItems, setCheckedItems] = useState<boolean[]>(new Array(accountLength).fill(false));
    const profile = useAppSelector(selectProfile);
    const [accountsToSave,setAccountsToSave] = useState<BankingAccountWriteModel[]>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleHeaderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setIsHeaderChecked(checked);
        setCheckedItems(new Array(accountLength).fill(checked));
        setIsButtonEnabled(checked);
        setAccountsToSave(bankingAccounts
            .filter(account => !account.isSelected)
            .map(account => {
                return {
                    ...account,
                    profileId: profile.id,
                    ownershipCategory: "Sole",
                    memberOwnerships: [],
                    legalEntityOwnerships: [],
                    isSelected: true
                };
            }
        ))
    }

    const handleItemChange = (index: number) => {
        const updatedCheckedItems = [...checkedItems];
        updatedCheckedItems[index] = !updatedCheckedItems[index];
        setCheckedItems(updatedCheckedItems);

        const isAnyItemChecked = updatedCheckedItems.some((isChecked) => isChecked);
        setIsButtonEnabled(isAnyItemChecked);
        if(isHeaderChecked && !updatedCheckedItems.every((isChecked) => isChecked)){
            setIsHeaderChecked(false);
        }
    };

    const handleAddAccount = () => {
        const selected = bankingAccounts.filter(
            ( account, index) =>
                checkedItems[index] && account.isSelected !== true);
        const confirmedAccounts: BankingAccountWriteModel[] = selected.map((account) : BankingAccountWriteModel => ({
            ...account,
            profileId: profile.id,
            ownershipCategory: "Sole",
            memberOwnerships: [],
            legalEntityOwnerships: [],
            isSelected: true
        }));
        setAccountsToSave(confirmedAccounts);
        setIsModalOpen(true);
    }

    const handleConfirm =()=> {
        onSave(accountsToSave);
        setIsModalOpen(false);
    }

    const isAllselected = bankingAccounts.every(
        (account) => account.isSelected === true
    );

    return (
        <div >
            <div aria-label="Search Results" className="banking-account-table">
                <div className="banking-account-table-header banking-account-grid">
                    <span className="marginleft-md">
                        <input className={`display-inherit`}
                               aria-label={`header-select`}
                               type="checkbox"
                               checked={isHeaderChecked || isAllselected}
                               onChange={handleHeaderChange}
                               disabled={isAllselected}
                        />
                    </span>
                    <span>Name</span>
                    <span>Account Number</span>
                    <span>Entity Type</span>
                    <span className="textalign-right">As of Date</span>
                    <span className="textalign-right">Present Value</span>
                </div>
                {
                    bankingAccounts.map((bankingAccount, index) => (
                        <div role="row" key={bankingAccount.accountPersistentId}>
                            <div
                                aria-label={`Account ${bankingAccount.accountNumber}`}
                                className="banking-account-table-row banking-account-grid"
                            >
                                <span className="marginleft-md">
                                    <input className={`display-inherit`}
                                           aria-label={`item-select`}
                                           type="checkbox"
                                           checked={bankingAccount.isSelected ? true : checkedItems[index]}
                                           onChange={() => handleItemChange(index)}
                                           disabled={!!bankingAccount.isSelected}
                                    />
                                </span>
                                <span>{bankingAccount.accountName}</span>
                                <span>{bankingAccount.accountNumber}</span>
                                <span>{bankingAccount.entityType}</span>
                                <span className="textalign-right">{bankingAccount.valueAsOfDate}</span>
                                <span className="textalign-right">
                        {bankingAccount.eodNtBalance !== null ? `${formatCurrency(bankingAccount.eodNtBalance)}` : noValue}
                    </span>
                            </div>
                        </div>
                    ))
                }
                <div
                    aria-label={`Add Account to Profile`}
                    className="banking-account-table-row add-account-row">
                    <Button
                        className="search-button marginleft-sm"
                        icon="left"
                        iconName="add"
                        kind="primary"
                        onClick={handleAddAccount}
                        size="medium"
                        type="button"
                        disabled={!isButtonEnabled}
                    >
                        Add Account(s) to Profile
                    </Button>
                </div>
                {isModalOpen &&
                    <AddBankingAccountModal
                        bankingAccounts={accountsToSave}
                        onConfirm={() => handleConfirm()}
                        onCancel={() => setIsModalOpen(false)}
                    />
                }
            </div>
        </div>
    )
}