import React from "react";
import {PresentationPaneHeader} from "../components";
import useProfileAndProposals from "src/hooks/useProfileAndProposals";
import {useParams} from "react-router-dom";
import { RouteWithId } from "src/routes/types";
import AddTrustButtonDropdown from "./components/AddTrustButtonDropdown";


const EstateFlowchart: React.FC = () => {
    const {id} = useParams<RouteWithId>();
    const displayName = useProfileAndProposals(id).approvedProfile.displayName;
    return (
        <div data-testid="estate-flowchart-header" className={"estate-flowchart"}>
            <PresentationPaneHeader
                displayName={displayName}
                title="Estate Flowchart"
            >
            </PresentationPaneHeader>
            <section className={"estate-flowchart-main"}>
                <h2 className={"add-trust-button-text"}>Begin by adding a trust</h2>
                <AddTrustButtonDropdown className={"add-trust-button"} />
            </section>
        </div>
    )
}

export default EstateFlowchart;